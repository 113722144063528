import React, { useEffect, useMemo, useState } from 'react';
import { useCartContext } from '~/components/CartContext/CartContext';
import { FeaturedKits } from '~/components/FeaturedKits/FeaturedKits';
import { StyledFeaturedKits } from '~/components/FeaturedKits/FeaturedKits.style';
import { KitProductCard } from '~/components/KitProductCard/KitProductCard';
import { ProductCard } from '~/components/ProductCard/ProductCard';
import { PLPGrid } from '~/components/ProductListPage/PLP.style';
import { StyledWishlistPage } from '~/components/WishlistPage/WishlistPage.styled';
import { useCreateWishlist } from '~/hooks/components/use-create-wishlist';
import { Section } from '~/styles/Section.styled';
import { Wrapper } from '~/styles/Wrapper.styled';
import { intentSlugs } from '~/types/constants';
import { IProduct, IntentSlug, Kit } from '~/types/types';

export const WishlistPage: React.FC<WishlistPageProps> = ({}) => {
  const { skus } = useCreateWishlist();
  const [kits, setKits] = useState<Kit[]>([]);
  const [products, setProducts] = useState<IProduct[]>();

  const { catalog, shopWithName } = useCartContext();

  const allProducts = useMemo(() => {
    return catalog.collections.flatMap(c => c.products.map(p => p)).filter((p, i, arr) => {
      return !!p && !p.hidden && arr.findIndex(c => c.slug === p.slug) === i;
    });
  }, [catalog]);


  const allKits = useMemo(() => {
    return catalog.collections.flat().map(col => col.kits).flat().filter((k, i, arr) => !!k && arr.indexOf(k) === i);
  },[catalog]);

  useEffect(() => {
    if (skus.length === 0) {
      return;
    }

    const productsInWishlist = allProducts.filter(product =>
      skus.includes(product.sku)
    );
    const kitsInWishlist = allKits.filter(kit => skus.includes(kit.sku));
    setKits(kitsInWishlist);
    setProducts(productsInWishlist);
  }, [skus, allProducts, allKits]);

  const productCards = useMemo(() => {
    return products?.map(product => {
      const intentCollections = product.collection?.filter(col => intentSlugs.includes(col.slug as IntentSlug) && col.slug !== 'all');
      return <ProductCard product={product} intentCollections={intentCollections} key={product.sku} />;
    });
  }, [products]);

  return (
    <StyledWishlistPage>
      <Section gutter>
        <div className="wrapper">
          {shopWithName ? (
            <h1>
              {shopWithName}'s <em>favorite</em> products
            </h1>
          ) : (
            <h1>A Curated Collection</h1>
          )}
        </div>
      </Section>
      <Section gutter>
        <div className="wrapper">
          <PLPGrid>
            {productCards?.map(card => card)}
          </PLPGrid>
        </div>
      </Section>
      <Section bg="green" gutter>
        <Wrapper>
          <StyledFeaturedKits>
            {kits.map(kit => (
              <KitProductCard kit={kit} key={kit.slug} />
              ))}
          </StyledFeaturedKits>
        </Wrapper>
      </Section>
    </StyledWishlistPage>
  );
};

interface WishlistPageProps {}
