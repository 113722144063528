import React from 'react';
import Layout from '~/components/Layout/Layout';
import Metadata from '~/components/Metadata';
import { WishlistPage } from '~/components/WishlistPage/WishlistPage';

const Wishlist: React.FC<WishlistProps> = ({}) => {
  return (
    <Layout>
      <Metadata title="Wishlist" />
      <WishlistPage />
    </Layout>
  );
};

export default Wishlist;

interface WishlistProps {}
