import styled from 'styled-components';
import { pxtorem } from '~/utils/tools';

export const StyledWishlistPage = styled.div`
  .wrapper {
    padding: ${pxtorem(0)} min(${pxtorem(80)}, 5%);
    h1 {
      margin-bottom: 0;
      line-height: 90%;
      max-width: 62.5vw;

      em {
        line-height: 100%;
      }
      @media (max-width: 1440px) {
        font-size: ${pxtorem(60)};

        em {
          font-size: ${pxtorem(50)};
        }
      }

      @media (max-width: 1024px) {
        max-width: 100%;

        font-size: ${pxtorem(40)};

        em {
          font-size: ${pxtorem(36)};
        }
      }
    }
  }

`;
