import { useEffect, useState } from 'react';

export const useCreateWishlist = () => {
  const [skus, setSkus] = useState<string[]>([]);

  useEffect(() => {
    function getSkusFromUrl() {
      const params = new URLSearchParams(window.location.search);
      const items = params.get('items');
      const amref = params.get('amref');
      const productSkus = [];
      for (const value of items.split(';')) {
        const [sku]: string[] = value.split(':');
        productSkus.push(sku);
      }
      setSkus(productSkus);
    }

    if (window.location.search && !skus.length) {
      getSkusFromUrl();
    }
  }, []);

  return { skus };
};
